@import "@/utils/mixins";
@import "@/utils/variables";

.splitHeading {
	color: $deep-green;
	text-align: center;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;

	@include tablet() {
		margin-bottom: 20px;
	}

	&.green {
		span {
			color: $light-green;
		}
	}

	&.yellow {
		span {
			color: $yellow;
		}
	}

	&.purple {
		span {
			color: $purple;
		}
	}

	&.dark {
		span {
			color: $deep-green;
		}
	}

	&.white {
		span {
			color: $white;
		}
	}

	span {
		display: block;
		color: $orange;
	}

	.subtitle {
		display: block;
		font-size: 2rem;
		line-height: 2.6rem;
		font-family: $base-font-family;
		font-weight: $bold-base;
		color: $orange;

		@include desktop() {
			&:not([class*="mobileSize"]) {
				font-size: 2.6rem;
				line-height: 3.4rem;
			}
		}
	}
}
