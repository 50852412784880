@import "@/utils/mixins";
@import "@/utils/variables";

.backButton {
	margin-top: 20px;

	@include desktop() {
		grid-column: 2 / span 10;
		margin-top: 40px;

		&.noMargin {
			margin-top: 0;
		}
	}

	&.noSpacing {
		margin-top: -25px;
	}
}
